.home {
    background-color: #030b6b;
    min-height: 100vh;
  
    & nav {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      color: #fff;
      & > .logo {
        color: #fff;
      }
    }
  }
  .home-links {
    display: flex;
    justify-content: center;
    align-items: center;
    & li a {
      color: #fff;
    }
    & > * {
      margin-left: 1rem;
      color: #fff;
    }
  }
  
  .hero {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .hero-text {
    width: 120%;
    & > * {
      color: #fff;
      margin-bottom: 2rem;
    }
    & > h2 span {
      display: block;
    }
    & .hero-buttons a {
      color: #fff;
    }
  }
  .hero-image {
    width: 120%;
  }
  @media screen and (max-width: 600px) {
    .hero {
      flex-direction: column;
    }
  
    .hero-text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 80%;
      margin: auto;
      text-align: center;
    }
    .hero-image {
      width: 100%;
      &img {
        width: 100%;
      }
    }
  }